<template>
  <div class="page team" :loaded="Boolean(page) && Boolean(directorsPage) && Boolean(members) && Boolean(directors)">
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.page_title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.subtitle.replace(/\n/g, '<br>')"></h3>
            <router-link class="btn white" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section ref="members" class="section members">
      <div class="wrapper" v-if="members">
        <div class="container">
          <div class="member" v-for="member in members" :key="member.id">
            <div class="member-image">
              <img :src="member.image" :alt="member.name">
            </div>
            <h5>{{member.name}}</h5>
            <p>{{member.role}}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section members" v-if="page && directorsPage && directors">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>Board of Directors</h3>
        </div>
        <!-- <div class="section-image">
          <img :src="directorsPage.image" alt="">
        </div> -->
        <div class="container">
          <div class="member bod" v-for="member in directors" :key="member.id">
            <div class="member-details">
              <div class="member-image">
                <img :src="member.image" :alt="member.name">
              </div>
              <p>{{member.role}}</p>
              <h5>{{member.name}}</h5>
              <p>{{member.company || 'Company Name'}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ContactForm />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "Team",
  components: {
    Icon,
    ContactForm,
  },
  data() {
    return {
      members: null,
      directors: null,
      page: null,
      directorsPage: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
    $route() {
      this.scrollToMembers();
    },
    page() {
      this.scrollToMembers();
    },
  },
  methods: {
    scrollToMembers() {
      if (this.$route.path.includes('our-team') && this.page) {
        this.$nextTick(() => {
          this.scrollToY(this.$refs.members.offsetTop);
        });
      }
    },
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.directorsPageRef) this.directorsPageRef();
      if (this.teamRef) this.teamRef();
      if (this.directorsRef) this.directorsRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("team")
        .onSnapshot((page) => {
          this.page = page.data();
        });
      this.directorsPageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("directors")
        .onSnapshot((page) => {
          this.directorsPage = page.data();
        });
      this.teamRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}team`)
        .orderBy("order")
        .onSnapshot((members) => {
          this.members = members.docs.map((doc) => doc.data());
        });
      this.directorsRef = this.db()
        .collection(`${this.testMode ? 'test_' : ''}directors`)
        .orderBy('order')
        .onSnapshot(members => {
          this.directors = members.docs.map(doc => doc.data());
        });
    },
  },
  mounted: function () {
    this.getItems();
    this.scrollToMembers();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.directorsPageRef) this.directorsPageRef();
    if (this.teamRef) this.teamRef();
    if (this.directorsRef) this.directorsRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 4rem;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 72px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: rgb(10,126,140);
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  margin-bottom: 32px;
  line-height: 1.1625;
  max-width: 940px;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.members {
  margin-bottom: 128px;
}
.members .section-title {
  margin-bottom: 96px;
}
.members .container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
}
.members .member {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: calc(33.333333% - 22px);
  padding: 40px 80px;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--Metallic-Seaweed);
  border-radius: 12px;
  transition: all 0.2s ease-out;
}
.members .member:hover {
  box-shadow: 0px 24px 32px 0px rgba(14, 58, 90, 0.08);
}
.members .member h5 {
  background: var(--Metallic-Seaweed);
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 16px 12px;
}
.members .member.bod h5 {
  margin-top: 12px;
}
.members .member.bod p:last-child {
  text-transform: uppercase;
}
.members .member .member-image {
  position: relative;
  padding-top: 100%;
  margin-bottom: 24px;
}
.members .member .member-image img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 3rem;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 6rem;
  }
  .top-section .container .content h3 {
    font-size: 3rem;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 1.75rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.3125rem;
  }
  .image-text h3 {
    font-size: 3rem;
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 1.75rem;
  }
  .image-text h5 >>> br {
    display: none;
  }
  .members .member {
    padding: 40px;
  }
  .members .member h5 {
    margin: 0 0 12px;
  }
}

@media screen and (max-width: 1024px) {
  .members .container {
    gap: 0;
  }
  .members .member {
    width: 33.333333%;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 2.5rem;
  }
  .section-title h5 {
    font-size: 1.5rem;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 4.5rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .image-text .container {
    display: block;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 24px 48px 0;
  }
  .members .member {
    padding: 24px;
  }
  .section-image {
    min-height: 240px;
  }
}

@media screen and (max-width: 680px) {
  .members .member {
    width: 50%;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 1.3125rem;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 3rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 1.3125rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.125rem;
  }
  .image-text h3 {
    font-size: 2rem;
  }
  .image-text h3 >>> br {
    display: none;
  }
  .image-text h5 {
    font-size: 1.5rem;
  }
  .image-text p {
    font-size: 1.3125rem;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
}

@media screen and (max-width: 480px) {
  .top-section .container .content h3 {
    font-size: 1.5rem;
  }
  .members .member {
    width: 100%;
  }
}
</style>
